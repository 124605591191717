import request from '@/assets/js/request';

/**
 * @description 公共api
 */

// 图片上传
export const uploadFileUrl = process.env.VUE_APP_BASE_API + 'v1/upload/file';

export function uploadFile(data) {
  return request({
    url: '/v1/upload/file',
    method: 'post',
    data,
  });
}
