<template>
  <el-upload
    class="uploader"
    :action="action"
    :deleteaction="deleteaction"
    :data="dataObj"
    :accept="accept"
    :headers="headers"
    :limit="limit"
    :file-list="fileList"
    :on-success="handleSuccess"
    :on-exceed="handleExceed"
    :before-upload="beforeUpload"
    :on-error="uploadError"
    :multiple="multiple"
    v-bind="$attrs"
    v-on="$listeners">
    <slot></slot>
  </el-upload>
</template>

<script>
import { getToken } from '@/assets/js/auth';
import { uploadFileUrl } from '@/api/common';

export default {
  props: {
    action: {
      type: String,
      default: uploadFileUrl,
    },
    deleteaction: {
      type: String,
      default: '',
    },
    dataObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    accept: {
      type: String,
      default: 'image/jpeg,image/png,application/pdf,image/vnd.dwg',
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {
    headers() {
      return {
        'X-TOKEN': getToken(),
      };
    },
  },
  methods: {
    validateMimeType(file) {
      let type;
      try {
        type = file.type || file.raw.type;
      } catch (err) {
        type = '';
      }
      if (type) {
        const mimeType = this.accept.split(',');
        return mimeType.includes(type);
      }
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
      return this.accept.indexOf(fileType) > -1;
    },
    handleSuccess(res, file, fileList) {
      this.$emit('success', file, fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!this.validateMimeType(file)) {
        this.$message.error(`接受上传文件的格式为：${this.accept}，请重新选择`);
        return false;
      } else if (!isLt2M) {
        this.$message.error('上传文件大小不能超过 2MB!');
        return false;
      }
    },
    uploadError() {
      this.$message.error('上传失败，请重试！');
    },
  },
};
</script>

<style lang="scss">
.uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.uploader .el-upload:hover {
  border-color: #409eff;
}
.uploader .uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.uploader .avatar {
  height: 200px;
  display: block;
}
</style>
